import { DataTreeView, IMenu, IMenuList, ITreeNodeActions, ITreeNodeData, ITreeNodeDataList } from '@app/muiplus'
import { TreeIterator, parseUrl } from '@app/utils'
import anylogger from '@app/anylogger'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const log = anylogger('MenuTree')

interface MenuTreeProps {
	items: IMenuList
	onNavigate?: (menu: IMenu) => void
	localStorageKey?: string
	fontSize?: string
}
export const MenuTree = React.forwardRef(function MenuTree(props: MenuTreeProps, ref: any) {
	const { items, onNavigate, localStorageKey, ...rest } = props
	const router = useRouter()
	const [adapted, setAdapted] = useState<ITreeNodeDataList>([])
	const [iterator, setIterator] = useState(new TreeIterator<MenuTreeAdapter>([]))
	const [selected, setSelected] = useState('')

	const pathComparer = (path: string) => {
		return (item: MenuTreeAdapter) => path === item.data.data
	}

	useEffect(() => {
		if (!items) return undefined
		const adaptedItems = MenuTreeAdapter.createList(items)
		setIterator(new TreeIterator(adaptedItems))
		setAdapted(adaptedItems)
	}, [items])
	useEffect(() => {
		if (!router?.asPath) return

		// extract the base path, ignoring any url parameters
		const { pathname } = parseUrl(router.asPath)
		iterator.findNode(pathComparer(decodeURI(pathname))).then((item) => {
			if (item) {
				let par = item.parent
				while (par) {
					par.expand()
					par = par.parent
				}
				setSelected(item.id)
			}
		})
	}, [adapted, iterator, router, router.asPath])

	const itemSelected = (data: IMenu) => {
		if (onNavigate) onNavigate(data)
	}
	return (
		<>
			<DataTreeView
				data={adapted}
				selected={[selected]}
				restrictExpansionToIcon
				onTreeNodeSelected={itemSelected}
				localStorageKey={localStorageKey}
				{...rest}
			/>
		</>
	)
})

class MenuTreeAdapter implements ITreeNodeData {
	data: IMenu
	id: string
	label: string
	parent: MenuTreeAdapter | undefined
	actions?: ITreeNodeActions

	static createList(items: IMenuList, parent?: MenuTreeAdapter) {
		return items.map((item) => {
			return new MenuTreeAdapter(item, parent)
		})
	}
	constructor(data: IMenu, parent?: MenuTreeAdapter) {
		this.data = data
		this.parent = parent
		this.id = parent?.data.caption + '/' + data.caption
		this.label = data.caption
	}
	getChildren() {
		const vals = this.data.children ?? []

		return Promise.resolve(MenuTreeAdapter.createList(vals, this))
	}
	setActions(actions: ITreeNodeActions) {
		this.actions = actions
	}
	expand() {
		if (this.actions) this.actions.setExpanded(true)
	}
}

import React, { useMemo, useContext, SetStateAction } from 'react'

export class LayoutOptions {
	showTitleBar!: React.Dispatch<SetStateAction<boolean>>
	showMenu!: React.Dispatch<SetStateAction<boolean>>
	backgroundOverride!: React.Dispatch<SetStateAction<string | undefined>>
}
export const LayoutServiceContext = React.createContext<LayoutOptions>(undefined!)

export function useLayoutService(): LayoutOptions {
	const context = useContext(LayoutServiceContext)
	return context
}

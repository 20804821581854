import { Backdrop, Button, ClickAwayListener, Popper, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Flex } from '@app/muiplus'
import { useSearch } from './Search'
import SearchResults from './SearchResults'

const log = anylogger('SearchResultsBackdrop')
export interface SearchResultsBackdropProps {
	anchorRef: any
	height: number
}

export const SearchResultsBackdrop = (props: React.PropsWithChildren<SearchResultsBackdropProps>) => {
	const { anchorRef, height } = props

	const { clear, isSearching } = useSearch()

	const cancelClick = useCallback(() => {
		clear()
	}, [clear])

	const dontCancel = useCallback((e: any) => {
		e.stopPropagation()
	}, [])

	if (!isSearching) return null

	return (
		<Backdrop
			open={true}
			onClick={cancelClick}
			sx={{
				position: 'absolute'
			}}
		>
			<Flex padding="0.5em" maxHeight={`${height}px`} height={`${height}px`} width="75%" mx="auto" onClick={dontCancel}>
				{/* We don't need a popper because we are manually using a backdrop and conditionally rendering on it */}
				{/* <Popper open={true} anchorEl={anchorRef} placement="bottom" disablePortal={true}> */}
				<SearchResults />
				{/* </Popper> */}
			</Flex>
		</Backdrop>
	)
}

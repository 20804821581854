import { useKeyBinder } from '@app/hooks'
import { Flex, FlexR, Text, useBreakpoint, useBreakpointValues } from '@app/muiplus'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Button } from '@mui/material'
import anylogger from '@app/anylogger'
import Image from 'next/image'
import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { useSearch } from 'src/services/Search/Search'
import SearchBox from 'src/services/Search/SearchBox'
import { useSession } from '../services/useSession'
import { ColorModeContext } from './ColorModeContext'
import { SessionStatus } from './SessionStatus'

const log = anylogger('TitleBar')

class Context {
	size: number = 0
	isMobile: boolean = false
	onMenuToggle: () => void = () => {}
	onHome: () => void = () => {}
	session: any
	loading: boolean = true
}
const TitleContext = createContext(new Context())

interface TitleBarProps {
	isMobile: boolean
	onMenuToggle: () => void
	onHome: () => void
}
export const TitleBar = React.forwardRef(function TitleBar(props: TitleBarProps, ref: any) {
	const { onMenuToggle, onHome, isMobile } = props

	const [session, loading] = useSession()
	const binder = useKeyBinder()
	const { clear } = useSearch()

	useEffect(() => {
		if (!binder) return
		binder.bindKey('Escape', clear)
		return () => {
			binder.unbindKey('Escape', clear)
		}
	}, [binder, clear])

	let size: number = useBreakpointValues<number>(200, { mobile: 40, tablet: 60, laptop: 80, desktop: 100 })

	const homeClick = () => {}
	const context = { size, isMobile, onMenuToggle, onHome, session, loading }
	return (
		<Flex alignItems="center">
			<TitleContext.Provider value={context}>
				<MenuToggle />
				<SiteIcon />
				<Flex fill>
					<SiteTitle />
				</Flex>
				{!isMobile && <SearchBox />}
				{!isMobile && <SessionStatus />}
				{/* <DarkModeSwitch /> */}
			</TitleContext.Provider>
		</Flex>
	)
})

const MenuToggle = React.forwardRef(function MenuToggle(props: any, ref: any) {
	const { isMobile, onMenuToggle: omMenuToggle } = useContext(TitleContext)
	if (!isMobile) return null
	return <MenuIcon onClick={omMenuToggle} />
})
const SiteIcon = React.forwardRef(function SiteIcon(props: any, ref: any) {
	const { size, onHome } = useContext(TitleContext)
	return (
		<Box style={{ cursor: 'pointer' }} onClick={onHome} width={`${size}px`} height={`${size}px`}>
			<Image width={size} height={size} src="/KCLogo.svg" alt="KC Logo" />
		</Box>
	)
})

const SiteTitle = React.forwardRef(function SiteTitle(props: any, ref: any) {
	const { size, onHome } = useContext(TitleContext)
	return (
		<Text
			width="100%"
			fontSize={`${size * 0.5}px`}
			fontWeight="bold"
			onClick={onHome}
			sx={{
				textAlign: 'center',
				cursor: 'pointer'
			}}
		>
			St. Peter Council #7070
		</Text>
	)
})

const DarkModeSwitch = React.forwardRef(function DarkModeSwitch(props: any, ref: any) {
	const { mode, toggleColorMode } = React.useContext(ColorModeContext)
	return (
		<FlexR>
			<Button variant="contained" onClick={() => toggleColorMode()}>
				{mode}
			</Button>
		</FlexR>
	)
})

import { Flex, FlexC, IMenu, IMenuList } from '@app/muiplus'
import CancelIcon from '@mui/icons-material/Cancel'
import { Drawer, IconButton } from '@mui/material'
import anylogger from '@app/anylogger'
import React, { useCallback } from 'react'
import { useSearch } from 'src/services/Search/Search'
import SearchBox from 'src/services/Search/SearchBox'
import SearchResults from 'src/services/Search/SearchResults'
import { MenuTree } from './MenuTree'
import { SessionStatus } from './SessionStatus'
const log = anylogger('Sidebar')

interface SidebarProps {
	top: number
	open: boolean
	items: IMenuList
	onNavigate?: (menu: IMenu) => void
	onClose: () => void
}
export const Sidebar = React.forwardRef(function Sidebar(props: SidebarProps, ref: any) {
	const { top, open, onClose, items, onNavigate } = props
	const { isSearching } = useSearch()

	const getContent = useCallback(() => {
		if (isSearching)
			return (
				<Flex fill bgcolor="gray" padding="0.5em" borderRadius="0.5em">
					<SearchResults />
				</Flex>
			)
		else
			return (
				<Flex fill>
					<MenuTree items={items} onNavigate={onNavigate} localStorageKey="MenuPosition" fontSize="125%" />
				</Flex>
			)
	}, [isSearching, items, onNavigate])

	return (
		<Drawer
			variant="temporary"
			open={open}
			onClose={onClose}
			ModalProps={{
				keepMounted: true // Better open performance on mobile.
			}}
			PaperProps={{ sx: { width: '100%' } }}
			sx={{ '& .MuiDrawer-paper': { mt: `${top}px`, pb: `${top}px` } }}
		>
			<FlexC fill component="nav" width="100%" p="0.5em" m="auto">
				<Flex justifyContent="space-between" alignItems="center">
					<SearchBox
						sx={{
							flexGrow: 1
						}}
					/>
					<Flex>
						<SessionStatus />
						<IconButton onClick={onClose}>
							<CancelIcon />
						</IconButton>
					</Flex>
				</Flex>

				{getContent()}
			</FlexC>
		</Drawer>
	)
})

import { Backdrop, Button, ClickAwayListener, Popper, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Highlight, Hits, Index, Snippet, UseHitsProps, useHits, useSearchBox } from 'react-instantsearch'
import anylogger from '@app/anylogger'
import { Flex, FlexC, H5, Text, TabFlex, TabPageList, useBreakpoint, useBreakpointValues } from '@app/muiplus'
import { useSearch } from './Search'
import { useComponentHeight, useKeyBinder } from '@app/hooks'
import { useSession } from '../useSession'
import { HitsRenderState } from 'instantsearch.js/es/connectors/hits/connectHits'
import Link from 'components/Link'
import { useRouter } from 'next/router'
import { NextLink } from '@app/uiutils'
import React from 'react'

const log = anylogger('SearchResults')

interface SearchResultsProps {}
const SearchResults = (props: SearchResultsProps) => {
	const [publicHits, setPublicHits] = useState<HitsRenderState>()
	const [memberHits, setMemberHits] = useState<HitsRenderState>()

	const [session, loading] = useSession()

	// This was a good idea (to send a "searching" flag to SearchResultsDisplay by tracking the changed public and member hits changes and returning false until you get the results),
	// but as soon as the search text changes the previous results are returned and it looks like the search is "finished", but the new results are still pending.
	// We are now waiting for the second set of results before setting searching to false.
	// This is definitely hacky, but if the results come back in one go, the worst is that it will not switch from an empty set of results to the populated tab.

	const [publicHitsPending, setPublicHitsPending] = useState(0)
	const [memberHitsPending, setMemberHitsPending] = useState(0)
	const [lastSearchText, setLastSearchText] = useState('')
	const { searchText, isSearching } = useSearch()
	useEffect(() => {
		if (!isSearching) return setLastSearchText(() => '')
		if (searchText == lastSearchText) return
		// log('---searchChanged')

		setLastSearchText(() => searchText)
		setPublicHitsPending(() => 0)
		setMemberHitsPending(() => 0)
	}, [isSearching, lastSearchText, searchText])

	const internalSetPublicHits = useCallback((val: any) => {
		setPublicHitsPending((old) => old + 1)
		setPublicHits(val)
		// log('gotPublic', val)
	}, [])
	const internalSetMemberHits = useCallback((val: any) => {
		setMemberHitsPending((old) => old + 1)
		setMemberHits(val)
		// log('gotMember', val)
	}, [])
	// log('render:', publicHitsPending, memberHitsPending)

	return (
		<>
			<Index indexName="MdxContent">
				{/* <GetHits onHits={setPublicHits} /> */}
				<GetHits onHits={internalSetPublicHits} />
			</Index>
			{session && (
				<Index indexName="Files">
					{/* <GetHits onHits={setMemberHits} /> */}
					<GetHits onHits={internalSetMemberHits} />
				</Index>
			)}
			<SearchResultsDisplay
				{...props}
				publicHits={publicHits}
				memberHits={memberHits}
				searching={publicHitsPending < 2 || memberHitsPending < 2}
			/>
		</>
	)
}

interface SearchResultsDisplayProps {
	publicHits?: HitsRenderState
	memberHits?: HitsRenderState
	searching: boolean
}
function GetHits({ onHits }: any) {
	const uhr = useHits()
	useEffect(() => {
		onHits(uhr)
	}, [onHits, uhr])
	return null
}

const SearchResultsDisplay = (props: SearchResultsDisplayProps) => {
	const { publicHits, memberHits, searching } = props
	const hasHits = publicHits?.hits.length || memberHits?.hits.length

	const [activeTab, setActiveTab] = useState<string>('Members')

	useEffect(() => {
		if (searching) return
		// log('Adjusting Tabs')
		if (!memberHits?.hits.length && publicHits?.hits.length) {
			// log('changing to public')
			setActiveTab((old) => 'Public')
		}
		if (memberHits?.hits.length && !publicHits?.hits.length) {
			// log('changing to members')
			setActiveTab((old) => 'Members')
		}
	}, [memberHits, publicHits, searching])

	const [session] = useSession()
	const isMobile = useBreakpoint('mobile')

	let buttonPadding: string = useBreakpointValues<string>('1em', { mobile: '1em', tablet: '2em', laptop: '3em', desktop: '5em' })

	const getResults = useCallback(() => {
		if (session) {
			const memberCaption = `Members (${memberHits?.hits.length})`
			const publicCaption = `Public (${publicHits?.hits.length})`
			return (
				<FlexC maxHeight="100%" bgcolor="gray" alignItems="stretch" justifyContent="start">
					<Flex justifyContent="center" bgcolor="white" p="0.5em" borderRadius="0.5em">
						<Button
							variant={activeTab == 'Public' ? 'outlined' : undefined}
							onClick={() => setActiveTab('Members')}
							sx={{
								px: buttonPadding
							}}
						>
							{memberCaption}
						</Button>
						<Button
							variant={activeTab == 'Members' ? 'outlined' : undefined}
							onClick={() => setActiveTab('Public')}
							sx={{
								px: buttonPadding
							}}
						>
							{publicCaption}
						</Button>
					</Flex>
					{activeTab == 'Members' && <MyHits {...memberHits!} />}
					{activeTab == 'Public' && <MyHits {...publicHits!} />}
				</FlexC>
			)
		} else if (publicHits) return <MyHits {...publicHits} />
		else return null
	}, [activeTab, buttonPadding, memberHits, publicHits, session])
	const binder = useKeyBinder()

	useEffect(() => {
		if (!hasHits) return () => {}
		const toggleTabs = () => {
			if (!session) return
			log('activeTab', activeTab)

			setActiveTab(activeTab == 'Public' ? 'Members' : 'Public')
		}
		binder.bindKey('Ctrl+K', toggleTabs)
		return () => {
			binder.unbindKey('Ctrl+K', toggleTabs)
		}
	}, [activeTab, binder, hasHits, session])

	return (
		<FlexC justifyContent="start" alignItems="center" mx="auto">
			{getResults()}
		</FlexC>
	)
}
export default SearchResults

function MyHits(props: HitsRenderState) {
	const { hits, results } = props
	const baseUrl = results?.index == 'Files' ? '/Members/Files' : ''

	const getResults = useCallback(() => {
		return hits.map((hit: any, idx: number) => <Hit key={idx} hit={hit} baseUrl={baseUrl} index={results ? results.index : ''} />)
	}, [baseUrl, hits, results])

	if (!hits) return null
	if (!hits.length)
		return (
			<Text width="100%" bgcolor="white" textAlign="center">
				No Results Found
			</Text>
		)
	return (
		<FlexC fill scroll>
			{getResults()}
		</FlexC>
	)
}
interface HitProps {
	baseUrl: string
	index: string
	hit: any
}
const Hit = (props: HitProps) => {
	const { hit, baseUrl, index } = props

	const getKeywords = useCallback((res) => {
		const objs = Object.keys(res).map((k) => res[k])
		const sorted = objs.sort((a: any, b: any) => {
			if (a.matchLevel == 'full' && b.matchLevel != 'full') return -1
			else if (b.matchLevel == 'full' && a.matchLevel != 'full') return 1
			else if (a.matchedWords.length > b.matchedWords.length) return -1
			else if (a.matchedWords.length < b.matchedWords.length) return 1
			else return 0
		})

		for (let ii = 0; ii < sorted.length; ii++) {
			const item = sorted[ii]
			if (item.matchedWords?.length) return item.matchedWords.join(' ')
		}
	}, [])

	const matched = getKeywords(hit._highlightResult)

	let hash = ''
	if (index == 'Files') hash = `page=${hit.page}`
	// let hash = ''
	if (matched) hash += `:~:text=${encodeURI(matched)}`
	const url = baseUrl + hit.url + `#${hash}`

	const { clear } = useSearch()

	// const onClick = useCallback(async () => {
	// 	navigate(url)
	// }, [navigate, url])

	return (
		<FlexC bgcolor="white" padding="0.5em" borderRadius="0.5em" fontSize="75%">
			{/* onClick={onClick} cursor="pointer" */}
			{/* <a href=""> */}
			{/* ! NextLink does not honour the :~:text=  hash for highlight results in the new page.  Chrome normally 
			   ! removes the hash once it applies it.  But when navigating with NextLink it stays on the address bar */}
			<a href={url} onClick={clear}>
				<FlexC>
					<Text fontWeight="bold" fontSize="125%">
						<Highlight attribute="title" hit={hit} />
					</Text>
					{`Page: ${hit.page}, Paragraph: ${hit.paragraph}`}
					{/* <Highlight attribute="content" hit={hit} /> */}
					<Snippet attribute="content" hit={hit}></Snippet>
				</FlexC>
			</a>
		</FlexC>
	)
}

import CancelIcon from '@mui/icons-material/Cancel'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import { useCurrentRef, useKeyBinder } from '@app/hooks'
import { useBreakpoint } from '@app/muiplus'
import anylogger from '@app/anylogger'
import React, { useCallback } from 'react'
import { useSearch } from './Search'

const log = anylogger('SearchBox')

interface SearchBoxProps {}
const SearchBox = React.forwardRef(function GeneralLayout(props: any, passedRef: any) {
	const { clear, search, searchText, ...rest } = useSearch()
	const [ref, setRef] = useCurrentRef<HTMLDivElement>(passedRef)
	const isMobile = useBreakpoint('mobile')

	const focusSearch = useCallback(() => {
		if (ref) ref.focus()
	}, [ref])
	const binder = useKeyBinder()
	binder.useKeyBinding('Ctrl-K', focusSearch)

	// useEffect(() => {
	// 	binder.bindKey('Ctrl+K', focusSearch)
	// 	return () => {
	// 		binder.unbindKey('Ctrl+K', focusSearch)
	// 	}
	// }, [binder, focusSearch])

	const onChange = useCallback(
		(e: any) => {
			const val = e.target.value
			search(val)
		},
		[search]
	)
	const cancelClick = useCallback(() => {
		clear()
	}, [clear])

	return (
		<TextField
			value={searchText}
			placeholder={isMobile ? 'Search' : 'Ctrl+K'}
			onChange={onChange}
			autoComplete="off"
			inputProps={{ ref: setRef }}
			InputProps={{
				// startAdornment: (
				// 	<InputAdornment position="start">
				// 		<SearchIcon />
				// 	</InputAdornment>
				// ),
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={cancelClick} edge="end">
							<CancelIcon />
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	)
})
export default SearchBox
